<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="3">
          <v-date-picker v-model="date"></v-date-picker>
        </v-col>
        <v-col cols="9">
          <v-row v-if="metricsLoading">
            <v-col v-for="n in 6" cols="2" :key="n">
              <v-skeleton-loader type="paragraph"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.revenue.toLocaleString()}} ₽</v-card-title>
                <v-card-text><a @click="showChart('revenue')">Выручка 24ч</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.revenue_month.toLocaleString()}} ₽</v-card-title>
                <v-card-text><a @click="showChart('revenue_month')">Выручка 30 дн</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.users.toLocaleString()}}</v-card-title>
                <v-card-text><a @click="showChart('users')">Пользователей</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.dau.toLocaleString()}}</v-card-title>
                <v-card-text><a @click="showChart('dau')">DAU</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.wau.toLocaleString()}}</v-card-title>
                <v-card-text><a @click="showChart('wau')">WAU</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.mau.toLocaleString()}}</v-card-title>
                <v-card-text><a @click="showChart('mau')">MAU</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.arpu.toLocaleString()}} ₽</v-card-title>
                <v-card-text><a @click="showChart('arpu')">ARPU</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.arppu.toLocaleString()}} ₽</v-card-title>
                <v-card-text><a @click="showChart('arppu')">ARPPU</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.cpi.toLocaleString()}} ₽</v-card-title>
                <v-card-text><a @click="showChart('cpi')">CPI</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.atv.toLocaleString()}} ₽</v-card-title>
                <v-card-text><a @click="showChart('atv')">ATV</a></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card>
                <v-card-title class="font-weight-bold">{{metrics.ret_rate.toLocaleString()}}%</v-card-title>
                <v-card-text><a @click="showChart('ret_rate')">Retention Rate</a></v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row v-if="dashboard.bot">
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.money_month.toLocaleString()}}</v-card-title>
            <v-card-text>Выручка за месяц</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.money_today.toLocaleString()}}</v-card-title>
            <v-card-text>Выручка сегодня</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.tokens_today.toLocaleString()}}</v-card-title>
            <v-card-text>Токенов сегодня</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.tokens_month.toLocaleString()}}</v-card-title>
            <v-card-text>Токенов за месяц</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">${{dashboard.bot.tokens_month_cost.toLocaleString()}}</v-card-title>
            <v-card-text>Цена токенов за месяц</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.images_month_cost.toLocaleString()}}</v-card-title>
            <v-card-text>Цена картинок за месяц</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.users_all.toLocaleString()}}</v-card-title>
            <v-card-text>Юзеров всего</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.users.toLocaleString()}}</v-card-title>
            <v-card-text>Юзеров реальных</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.users_today.toLocaleString()}}</v-card-title>
            <v-card-text>Юзеров сегодня</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.users_active.toLocaleString()}}</v-card-title>
            <v-card-text>Активных юзеров (24ч)</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.mau.toLocaleString()}}</v-card-title>
            <v-card-text>MAU</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.banned.toLocaleString()}}</v-card-title>
            <v-card-text>Забанили нас</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.images_today.toLocaleString()}}</v-card-title>
            <v-card-text>Картинок сегодня</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.images_month.toLocaleString()}}</v-card-title>
            <v-card-text>Картинок за месяц</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.messages_today.toLocaleString()}}</v-card-title>
            <v-card-text>Сообщений сегодня</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-title class="font-weight-bold">{{dashboard.bot.messages_month.toLocaleString()}}</v-card-title>
            <v-card-text>Сообщений за месяц</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <dash-chart-dialog :show.sync="chartDialog" :param="chartParam"></dash-chart-dialog>
  </v-container>
</template>

<script>

import {computed, ref, watch} from "@vue/composition-api";
import store from "@/store";
import httpClient from "@/services/http.service";
import DashChartDialog from "@/views/dashboard/DashChartDialog";

export default {
  name: "Dashboard",
  components: {DashChartDialog},
  setup() {
    const dashboard = computed(()=>store.state.dashboard)

    const date = ref(null)
    const metrics = ref([])
    const metricsLoading = ref(false)
    const chartParam = ref('')
    const chartDialog = ref(false)

    watch(date, (newVal) => {
      getMetrics(newVal)
    })

    const showChart = (param) => {
      chartDialog.value = true
      console.log('showChart', param)
      chartParam.value = param
    }

    const getMetrics = (date = null) => {
      metricsLoading.value = true
      httpClient.get('dashboard/metrics?date='+date)
        .then((response) => {
          console.log('metrics', response.data)
          metrics.value = response.data
          metricsLoading.value = false
      })
        .catch((error) => {
          console.log(error)
          metricsLoading.value = false
        })
    }

    getMetrics()

    return {
      dashboard, metrics, metricsLoading, date, chartParam, chartDialog,
      showChart
    }
  },
}
</script>
