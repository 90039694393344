<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card>
      <v-card-text>
        <vue-apex-charts
          type="line"
          :options="chartOptions"
          :series="values"
          height="600"
        ></vue-apex-charts>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import httpClient from "@/services/http.service";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "DashChartDialog",
  components: {
    VueApexCharts
  },
  props:{
    param: null,
    date: null,
    show: Boolean
  },
  setup(props, {emit}){
    const values = ref([])
    const paramLocal = computed(()=>props.param)
    const dialog = computed({
      get(){
        return props.show
      },
      set(val){
        emit('update:show', val)
      }
    })
    const chartOptions = ref({
      chart: {
        type: 'line',
        height: 300,
        width: '80%',
        offsetX: '5%',
        offsetY: '5%'
      },
      grid: {
        padding: {
          left: 50,
          right: 50,
          top: 30,
          bottom: 30
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,

      },
      // colors: ['#77B6EA'],
      dataLabels: {
        enabled: true,
        position: 'top',
        formatter: function (val, opts) {
          return val.toLocaleString()
        }
      },
      stroke: {
        width: 7,
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: [],
        labels: {
          formatter: function(value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), 'dd MMM')
          }
        }
      },
      yaxis:{
        labels: {
          formatter: function (val) {
            return val.toLocaleString()
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: [ '#FDD835'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        },
      }
    })

    watch(dialog, (newVal)=>{
      if(!newVal){
        values.value = []
      }else{
        httpClient.get(`/dashboard/chart?param=${paramLocal.value}&date=${newVal}`).then(res=>{
          console.log('chart', res.data)
          // values.value = {data: res.data.values}
          values.value = [{
            type: 'line',
            name: paramLocal.value,
            data: []
          }]
          res.data.forEach(m=>{
            console.log('m', m)
            values.value[0].data.push({x: new Date(m.date), y: m[paramLocal.value]})
            chartOptions.value.xaxis.categories.push(m.date)
          })
          chartOptions.value.yaxis.min = Math.min(...values.value[0].data.map(m=>m.y)) * 0.8
          chartOptions.value.yaxis.max = Math.max(...values.value[0].data.map(m=>m.y)) * 1.2
          // chartOptions.value.yaxis.tickAmount = (chartOptions.value.yaxis.max - chartOptions.value.yaxis.min / 20).toFixed(2) + 1
          chartOptions.value.xaxis.range = chartOptions.value.xaxis.categories.length + 1
          // chartOptions.value.xaxis.categories = res.data.labels
          console.log('values', chartOptions.value.yaxis)
        })
      }
    })
    // watch(param, (newVal, oldVal)=>{
    //   if(newVal){
    //     httpClient.get(`/dashboard/chart?param=${param.value}&date=${newVal}`).then(res=>{
    //       values.value = res.data
    //     })
    //   }
    // })
    return{
      values, chartOptions, dialog, paramLocal,
    }
  }
}
</script>

<style scoped>

</style>
